import React from 'react';
import explain from './explain.module.less';
export default function () {
  const list = [
    [
      ['办公费', '办公费用'],
      ['保洁费', '办公费用'],
      ['差旅费', '办公费用'],
      ['钢琴搬运费', '办公费用'],
      ['快递费', '办公费用'],
      ['市内车费', '办公费用'],
      ['通讯费', '办公费用'],
      ['学员福利费', '办公费用'],
      ['业务招待费', '办公费用'],
      ['车位费', '办公费用'],
    ],
    [
      ['维修费', '房租物业装修维修'],
      ['房租费', '房租物业装修维修'],
      ['物业费', '房租物业装修维修'],
      ['装修费摊销', '房租物业装修维修'],
    ],

    [
      ['物料采购', '教材和物料'],
      ['教材采购', '教材和物料'],
      ['合同+收据', '教材和物料'],
    ],
    [
      ['固定资产-办公用品', '乐器、贵重资产采购'],
      ['电钢采购', '乐器、贵重资产采购'],
      ['真钢采购', '乐器、贵重资产采购'],
      ['吉他采购', '乐器、贵重资产采购'],
      ['预采购存货', '乐器、贵重资产采购'],
      ['固定资产-静音舱', '乐器、贵重资产采购'],
    ],
    [
      ['宣传推广费-兼职', '市场费用'],
      ['宣传推广费-流量', '市场费用'],
      ['宣传推广费-推广活动', '市场费用'],
      ['中台市场成本', '市场费用'],
      ['市场费用充值', '市场费用'],
    ],
    [
      ['宽带费', '水电宽带'],
      ['水电费', '水电宽带'],
    ],
    [['技术服务费', '技术服务费']],
    [
      ['团建费', '团建福利培训'],
      ['员工培训费', '团建福利培训'],
      ['员工福利费', '团建福利培训'],
      ['招聘费', '团建福利培训'],
    ],
    [['兼职课时费', '薪资绩效']],
  ];
  return (
    <div className={explain.outer}>
      <div className={explain.title}>分类对照表</div>
      <div className={explain.table}>
        <div className={explain.tr}>
          <div className={explain.th}>二级分类</div>
          <div className={explain.th}>一级分类</div>
        </div>

        {list.map((x, y) => {
          return (
            <div className={explain.item} key={'item' + y}>
              {x.map((m, n) => {
                return (
                  <div key={'tr' + n} className={explain.tr}>
                    <div
                      style={{
                        fontFamily: 'PingFangSC-Semibold, PingFang SC',
                        fontWeight: 600,
                      }}
                      className={explain.td}
                    >
                      {m[0]}
                    </div>
                    <div
                      style={{
                        fontFamily: 'PingFangSC-Regular, PingFang SC',
                        fontWeight: 400,
                      }}
                      className={explain.td}
                    >
                      {m[1]}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}
